// Import our depdendency
import myFetch from 'api/myFetch'
import myFetchBlob from 'api/myFetchBlob'
import myPost from 'api/myPost'
import myPut from 'api/myPut'
import authApi from 'auth/api'
import myDelete from 'api/myDelete'
import store from 'store/index'
import { Callout } from '@blueprintjs/core'
import { AppToaster } from './../shared/Toaster/Toaster'

import {
  storeAllPublications,
  storeNewPublication,
  storeActivePublication,
  changeLoading,
  creatingPublication,
  updateHeavyDataObject,
  addContextDataObject,
} from './actions'
import config from 'config'
import {
  deserializeFlightDuties,
  deserializeActivities,
  deserializePairings,
  deserializeNoContracts,
  deserializeIssues,
  deserializeMetrics,
} from './motuplanJsonHelpers'

const servicesFactory = (myFetch, myPost, myDelete, myPut, myFetchBlob) => ({
  async getDataObject(object_id) {
    let json = await myFetch(config.api + `/data_objects/${object_id}/`)
    const action = addContextDataObject(json)
    store.dispatch(action)
  },
  async getHeavyDataObject(pub_id, object_id) {
    let json = await myFetch(config.api + `/data_objects/${object_id}/`)

    const action = updateHeavyDataObject({ pub_id, object_id, json })
    store.dispatch(action)
  },

  async downloadFile(api_url) {
    let { blobPromise, filename } = await myFetchBlob(api_url)
    let blob = await blobPromise
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    a.remove()
  },
  async validatePublication(id) {
    await myFetch(`${config.api}/publications/${id}/validate/`)
  },
  downloadCoverageDataIssuesCsv(id) {
    this.downloadFile(`${config.api}/coverage_check_rosters/${id}/data_issues.csv`)
  },
  downloadCoverageRosterIssuesCsv(id) {
    this.downloadFile(`${config.api}/coverage_check_rosters/${id}/roster_issues.csv`)
  },

  downloadRosterJson(id) {
    this.downloadFile(`${config.api}/rosters/${id}/json/`)
  },
  downloadRosterCsv(id) {
    this.downloadFile(`${config.api}/rosters/${id}/csv/`)
  },
  downloadRosterZip(id) {
    this.downloadFile(`${config.api}/rosters/${id}/zip/`)
  },
  downloadRosterIssuesCsv(id) {
    this.downloadFile(`${config.api}/rosters/${id}/issues.csv`)
  },

  enrichRoster(data) {
    data?.forEach((r) => {
      deserializeIssues(r)
      deserializeMetrics(r)
      deserializeFlightDuties(r)
      deserializeActivities(r)
      deserializePairings(r)
      deserializeNoContracts(r)
    })
  },

  async getMotuplanCoverageCheckRoster(id) {
    let json = await myFetch(config.api + `/coverage_check_rosters/${id}/`)
    let aircraftRoster = []
    this.enrichRoster(json.data.roster)
    json.data.aircraftRoster = aircraftRoster
    return json.data
  },

  async getMotuplanRoster(id) {
    let json = await myFetch(config.api + `/rosters/${id}/`)
    let aircraftRoster = []

    this.enrichRoster(json.data.roster)
    json.data.aircraftRoster = aircraftRoster
    return json.data
  },

  async createEvent(type, publication, payload, context, success) {
    return myPost(config.api + `/events/`, {
      body: JSON.stringify({ type, publication, payload, context }),
    })
      .then((json) => {
        if (json.result?.exception === 'FailedEvent') {
          return Promise.reject({ type: 'FailedEvent', msg: json.result.message })
        }
        return this.getPublication(publication).then(() => {
          if (success) success()
        })
      })
      .catch((err) => {
        AppToaster.show({
          intent: 'danger',
          className: 'not-found-toast',
          autoFocus: false,
          icon: 'error',
          timeout: 0,
          message: err.type === 'FailedEvent' ? err.msg : 'You are not authorized to perform this action',
        })
      })
  },
  async updatePublication(id, data, updateActive, context) {
    try {
      let json = await myPut(config.api + `/publications/${id}/`, {
        body: JSON.stringify(data),
      })
      if (updateActive) {
        const action = storeActivePublication(json)
        store.dispatch(action)
      } else {
        await this.getAllPublications(context)
      }
    } catch (errors) {
      return Promise.reject({ name: ['Publication name and dates must be unique'] })
    }
  },
  async clonePublication(id, context) {
    await myPost(config.api + `/publications/${id}/clone/`)
    await this.getAllPublications(context)
  },
  async archivePublication(id, context) {
    const json = await myPost(config.api + `/publications/${id}/archive/`)
    await this.getAllPublications(context)
  },
  async unarchivePublication(id, context) {
    const json = await myPost(config.api + `/publications/${id}/unarchive/`)
    await this.getAllPublications(context)
  },
  async getAllPublications(context) {
    store.dispatch(changeLoading(true))
    let json = await myFetch(config.api + `/publications/${context ? `?context=${context}` : ''}`)
    json.forEach((pub) => {
      if (pub.data_objects)
        pub.data_objects
          .filter((d) => d.is_editable)
          .forEach((d) => {
            if (d.payload) if (d.payload.items) d.payload.items.forEach((item, i) => (item.key = i))
          })
    })
    const action = storeAllPublications(json)
    store.dispatch(action)
    store.dispatch(changeLoading(false))
  },

  async getPublication(id) {
    store.dispatch(changeLoading(true))
    try {
      let json = await myFetch(config.api + `/publications/${id}/`)

      const action = storeActivePublication(json)
      store.dispatch(action)
    } catch (err) {
      store.dispatch(changeLoading(false))
    }
    store.dispatch(changeLoading(false))
  },
  async createNewPublication(
    context,
    name,
    description,
    calculation_mode,
    data_src,
    data_dst,
    data_dst_roster_from,
    data_dst_roster_until,

    first_day,
    last_day,
    no_auto_trigger_event_types
  ) {
    try {
      store.dispatch(creatingPublication(true))

      let json = await myPost(config.api + `/publications/`, {
        body: JSON.stringify({
          context,
          name,
          calculation_mode,
          description,
          data_src,
          data_dst,
          data_dst_roster_from,
          data_dst_roster_until,
          first_day,
          last_day,
          no_auto_trigger_event_types,
        }),
      })
      const action = storeNewPublication(json)
      store.dispatch(action)
      store.dispatch(creatingPublication(false))
    } catch (errors) {
      return Promise.reject({ name: ['Publication name and dates must be unique'] })
    }
  },
})

const api = servicesFactory(myFetch, myPost, myDelete, myPut, myFetchBlob)
export default api
