import React from 'react'
import { TextArea, InputGroup } from '@blueprintjs/core'
import Validate from './Validate'
const InputText = (props) => {
  const { error, onChange, placeholder, value, area, errorposition } = props

  const componentProps = {
    ...props,
    autoFocus: true,
    placeholder: placeholder ?? '',
    intent: error ? 'danger' : 'undefined',
    value: value || '',
    onChange: ({ target }) => {
      onChange(target.value)
    },
  }

  return (
    <Validate validation_errors={error} position={errorposition || 'right'} fill={props.fill}>
      {!area && <InputGroup {...componentProps} />}
      {area && <TextArea {...componentProps} />}
    </Validate>
  )
}

export default InputText
