import React, { useState } from 'react'
import { Tooltip2 } from '@blueprintjs/popover2'
import { Icon, ControlGroup, Pre, H4, H6, Navbar, Button, Alignment, Divider } from '@blueprintjs/core'
import { useSelector } from 'react-redux'
import Editor from './Editor.js'
import moment from 'moment'
import { intl } from 'i18n/IntlGlobalProvider'
import { Rows, Row, Cols, Col } from 'shared/PanelLayout/PanelLayout'
import styles from './Data.module.scss'

import { AppToaster } from './../shared/Toaster/Toaster'
import ErrorBoundary from 'log/ErrorBoundary'
import myFileUpload from 'api/myFileUpload'
import api from './api'
import store from 'store'
import { addContextDataObject } from './actions'
import { FileUpload } from 'shared/Core'
import TooltipButton from 'shared/TooltipButton'

const DataObject = (props) => {
  const { filter, type, data, title } = props
  const [errorOpen, setErrorOpen] = useState(true)
  const [showJson, setShowJson] = useState(props.json)
  const user = data.last_modified_by
  const [showFilter, setShowFilter] = useState(true)

  const [file, setFile] = useState(null)

  const onFileChange = (event) => {
    // Update the state
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = function (e) {
      try {
        if (props.upload === 'csv') {
          api.uploadCsvFile(data.id, file)
        } else if (props.upload === 'xlsx') {
          api.uploadXlsxFile(data.id, file)
        } else {
          let json = JSON.parse(reader.result)
          props.onChange({ ...data, dirty: true, payload: json })
        }
      } catch (err) {
        AppToaster.show({
          intent: 'danger',
          className: 'not-found-toast',
          autoFocus: false,
          icon: 'error',
          timeout: 0,
          message: <span>Invalid file. {err.detail} </span>,
        })
      }
    }
    reader.readAsText(file)
  }

  return (
    <Cols>
      <Col>
        <Rows>
          <Row fixed>
            <Navbar>
              <Navbar.Group>
                <Navbar.Heading>{title}</Navbar.Heading>

                {props.tabbed && !showJson && props.tabbed}
                <span className={styles.muted} style={{ marginLeft: 20, marginRight: 20 }}>
                  last modified by {props.data.created_by_name} - {intl.calendar(moment(props.data.last_modified_at))}
                </span>
              </Navbar.Group>
              <Navbar.Group align={Alignment.RIGHT} style={{ paddingLeft: 10 }}>
                {data.dirty && (
                  <>
                    <Button
                      minimal
                      icon="floppy-disk"
                      onClick={() => {
                        props
                          .onSave(data.id, data.payload)
                          .then((json) => (props.onSaveSuccess ? props.onSaveSuccess(json) : undefined))
                          .catch((err) => {
                            //    if (props.onValidationError) props.onValidationError(err)

                            if (showJson)
                              AppToaster.show({
                                intent: 'danger',
                                className: 'not-found-toast',
                                autoFocus: false,
                                icon: 'error',
                                timeout: 0,
                                message: (
                                  <span>
                                    <Pre>{JSON.stringify(err, null, 2)}</Pre>
                                  </span>
                                ),
                              })
                          })
                      }}
                    />

                    <TooltipButton
                      tooltip="Undo all changes"
                      minimal
                      icon="undo"
                      onClick={() => {
                        api.getDataObject(props.data.id)
                        if (props.undo) props.undo()
                      }}
                    />
                  </>
                )}
                {props.search}
                {!showJson && props.onAdd && props.is_editable && (
                  <TooltipButton
                    tooltip="Add new"
                    intent="primary"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    icon="plus"
                    text={props.addButtonText || 'Add'}
                    onClick={props.onAdd}
                  />
                )}

                {props.data.is_editable && <FileUpload upload={props.upload} onChange={onFileChange} />}
                <TooltipButton
                  minimal
                  icon={!showJson ? 'code' : 'form'}
                  onClick={() => setShowJson(!showJson)}
                  tooltip={showJson ? 'Toggle UI Editor' : 'Toggle JSON Editor'}
                />

                {!showFilter && !showJson && (
                  <TooltipButton tooltip="Show Filter" minimal icon="filter" onClick={() => setShowFilter(true)} />
                )}
              </Navbar.Group>
            </Navbar>
          </Row>
          {showJson && (
            <Row>
              <Editor
                data={data.payload}
                is_editable={data.is_editable}
                onChange={(payload) => {
                  // props.onChange({ ...data, dirty: true, payload: payload })
                  store.dispatch(addContextDataObject({ ...data, dirty: true, payload: payload }))
                }}
              />
            </Row>
          )}
          {!showJson && (
            <Row muted>
              <ErrorBoundary>{props.children}</ErrorBoundary>
            </Row>
          )}
        </Rows>
      </Col>
      {filter && !showJson && (
        <Col width={250} collapse="right" collapsed={!showFilter} dark shadow responsive>
          <Rows>
            <Row fixed>
              <Navbar>
                <Navbar.Group>
                  <Navbar.Heading>Filters & Actions</Navbar.Heading>
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                  <Button
                    minimal
                    icon="cross"
                    onClick={() => setShowFilter(false)}
                    style={{ right: 10, position: 'absolute' }}
                  />
                </Navbar.Group>
              </Navbar>
            </Row>
            <Row padding={10}>{filter}</Row>
          </Rows>
        </Col>
      )}
    </Cols>
  )
}

export default DataObject
