import { DateTime, Interval } from 'luxon'
import { startOf, endOf } from 'shared/LuxonHelpers'
import React, { useEffect, useState, useRef } from 'react'
import styles from './Cursor.module.scss'

const OutsidePublication = (props) => {
  const { period } = props
  const x = props.xScale
  const roomAvailable = React.useMemo(() => x(3600 * 24 * 1000) - x(0), [x])

  if (!period[0] || !period[1]) return null
  const p = React.useMemo(() => period.map((d) => x(startOf(d, props.timezone, 'day')), [props.timezone, period, x]))

  const style1 = React.useMemo(
    () => ({
      left: 0,
      //   display: p[0] - props.x > 0 ? 'visible' : 'none',
      height: '100%',
      width: p[0],
    }),
    [p[0], props.x]
  )

  const style2 = React.useMemo(
    () => ({
      //   display: p[1] < props.x + props.width ? 'visible' : 'none',
      left: p[1],
      height: '100%',
      width: x.range()[1] - p[1],
    }),
    [p[1], props.x, props.width]
  )
  return (
    <>
      <div className={styles.outSidePublication} style={style1} />
      <div className={styles.outSidePublication} style={style2} />
    </>
  )
}

export default React.memo(OutsidePublication)
