import { useSelector } from 'react-redux'
import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import * as d3 from 'd3'
import { lux, startOf, endOf } from 'shared/LuxonHelpers'
import RosterGantt from './RosterGantt2'
import RosterHeader from './RosterHeader'
import RosterHeatmap from './RosterHeatmap'
import { useHotkeys } from '@blueprintjs/core'
import { changeFilter } from '../actions'
import { DndContext } from '@dnd-kit/core'
import CrewMemberLabel from './CrewMember/CrewMemberLabel/CrewMemberLabel'
import PlaneLabel from './CrewMember/CrewMemberLabel/PlaneLabel'
import HeatmapLabel from './CrewMember/CrewMemberLabel/HeatmapLabel'
import CrewMember from './CrewMember/CrewMember'
import PlaneMember from './CrewMember/PlaneMember'
import { PointerSensor, useSensor, useSensors } from '@dnd-kit/core'

import store from 'store'
import { useMouse } from './mouseHooks'
import activityStyles from './CrewMember/Activity/Activity.module.scss'
import crewMemberstyles from './CrewMember/CrewMember.module.scss'

import { SplitPane, Pane } from 'shared/SplitPane/SplitPane'
import { zipObjectDeep } from 'lodash'
import { WidgetHeader } from '@blueprintjs/icons/lib/esm/generated-icons/16px/paths'

const DisableChromeZoom = (event) => {
  if (event.ctrlKey) {
    event.preventDefault()
  }
}
const getScale = (min, max, width, zoom) =>
  d3
    .scaleUtc()
    .domain([min, max])
    .range([0, width * zoom])

const HeatmapRow = (props) => {
  return (
    <div
      style={{ position: 'relative', height: 20 }}
      className={[props.data?.odd ? crewMemberstyles.odd : crewMemberstyles.even].join(' ')}>
      <div style={{ position: 'absolute', width: '100%', display: 'flex', height: 20, top: 0 }}>
        {props.data?.values.map((d) => (
          <div
            className={activityStyles.activity}
            style={{ top: 0, left: d.x, height: 20, width: d.width, backgroundColor: `rgba(0,0,255,${d.value / 10})` }}>
            {d.value}
          </div>
        ))}
      </div>
    </div>
  )
}
const Split = (props) => {
  const [width, setWidth] = useState(100)
  const [left, setLeft] = useState(100)
  const ref = useRef()
  const panesRef = useRef()

  const [scrollX, setScrollX] = useState({
    xScale: getScale(0, 100000000, 1000, 6),
    source: 'zoom',
    width: 1000 - 340,
    zoom: 6,
    x: undefined,
  })

  const filteredCrew = useSelector((state) => state.rosters.filter.searchPlCode)
  const [dragInProgress, setDragInProgress] = useState(false)
  const [resetSavedScroll, setResetSavedScroll] = useState(0)

  const filter = useSelector((state) => state.rosters.filter)
  const { displaySafeCare } = filter
  const [isDragging, setIsDragging] = useState(false)

  let min = useMemo(
    () =>
      startOf(
        Math.min(...props.rosterData?.map((e) => Math.min(...e.activities.map((d) => d.startTime)))) || 0,
        'UTC',
        'day'
      ) || new Date(props.pubStart).getTime(),
    [props.rosterData]
  )
  let max = useMemo(
    () =>
      endOf(
        Math.max(
          ...props.rosterData?.map((e) => Math.max(...e.flight_duties.map((d) => d.endOfRest))),
          ...props.rosterData?.map((e) => Math.max(...e.activities.map((d) => d.endTime)))
        ) || 0,
        'UTC',
        'day'
      ) || new Date(props.pubEnd).getTime(),
    [props.rosterData]
  )

  const zoomDo = React.useCallback(
    (factor, left, w, e) => {
      const oldZoom = scrollX.zoom
      const newZoom = Math.min(Math.max(1, factor * oldZoom), 100)
      const width = w - 340
      const xScale = getScale(min, max, 1000, newZoom)

      const maxWidth = xScale.range()[1]

      const realFactor = newZoom / oldZoom
      const xposition = e?.clientX - 340
      let originalX = scrollX.x
      if (originalX === undefined) originalX = maxWidth / 2 - width / 2
      const zoomAroundPosition = xposition || left + width / 2

      const offset = originalX + (zoomAroundPosition - left)

      let newX = Math.max(0, realFactor * offset - (offset - originalX))
      newX = Math.min(newX, maxWidth - width)

      setScrollX({ xScale, width, source: 'zoom', zoom: newZoom, x: newX })
    },
    [min, max, scrollX]
  )

  const zoomIn = () => zoomDo(1.5, left, width)
  const zoomOut = () => zoomDo(1 / 1.5, left, width)

  useEffect(() => {
    window.addEventListener('wheel', DisableChromeZoom, { capture: true, passive: false })
    return () => {
      window?.removeEventListener('wheel', DisableChromeZoom, { capture: true, passive: false })
    }
  }, [])

  const pointerSensor = useSensor(PointerSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 0,
      tolerance: 5,
    },
  })
  const sensors = useSensors(pointerSensor)
  const hotkeys = [
    {
      combo: 'esc',
      global: true,
      onKeyDown: () => {
        store.dispatch(
          changeFilter({
            searchPlCode: [],
            searchPlane: [],
          })
        )
        setResetSavedScroll(true)
        setTimeout(() => setResetSavedScroll(false), 10)
      },
    },
    {
      combo: 'plus',
      global: true,
      onKeyDown: zoomIn,
      preventDefault: true,
      stopPropagation: true,
    },
    {
      combo: 'minus',
      global: true,
      onKeyDown: zoomOut,
      preventDefault: true,
      stopPropagation: true,
    },
    {
      combo: 'ctrl+up',
      global: true,
      onKeyDown: zoomIn,
      preventDefault: true,
      stopPropagation: true,
    },
    {
      combo: 'ctrl+down',
      global: true,
      onKeyDown: zoomOut,
      preventDefault: true,
      stopPropagation: true,
    },
    {
      combo: '=',
      global: true,
      onKeyDown: zoomIn,
      preventDefault: true,
      stopPropagation: true,
    },
  ]
  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys)

  // drag of activity
  function handleDragStart(event) {
    setIsDragging(event.active.id)
  }

  function handleDragEnd(event) {
    const { active, over } = event

    setIsDragging(false)

    if (over) {
      // do stuff

      if (over.data.current.crewFunction !== 'Plane') props.onAddActivity([active?.data?.current], over?.data?.current)
    }
  }

  const setDragStart = useCallback(() => {
    setDragInProgress(true)
  }, [])
  const setDragEnd = useCallback(() => {
    setDragInProgress(false)
  }, [])

  const topDataToShow = React.useMemo(
    () => (props.topDataFilter ? props.topDataFilter(props.topData, filter) : props.topData),
    [props.topDataFilter, props.topData, filter]
  )
  const bottomDataToShow = React.useMemo(
    () => (props.bottomDataFilter ? props.bottomDataFilter(props.bottomData, filter) : props.bottomData),
    [props.bottomDataFilter, props.bottomData, filter]
  )
  const period = React.useMemo(
    () =>
      filter.selectedDay
        ? [startOf(filter.selectedDay, filter.timezone, 'day'), endOf(filter.selectedDay, filter.timezone, 'day')]
        : [startOf(props.pubStart, filter.timezone, 'day'), endOf(props.pubEnd, filter.timezone, 'day')],
    [props.pubStart, props.pubEnd, filter.selectedDat, filter.timeZone]
  )

  const splitPlaneConfig = React.useMemo(
    () => [
      {
        id: 'heatmap',
        pane: 'heatmap',
        title: 'heatmap',
        rowHeight: 20,
        LabelElement: HeatmapLabel,
        RowElement: HeatmapRow,
        data: props.heatmap,
        onLabelAdd: props.onTopAdd,
        onLabelClick: props.onEmployeeClick ? props.onEmployeeClick : undefined,
        onClick: props.onTopRowClick ? props.onTopRowClick : undefined,
        onActivityClick: props.onTopActivityClick,
      },
      {
        id: 'crew',
        pane: 'crew',
        title: 'Crew',
        LabelElement: CrewMemberLabel,
        RowElement: CrewMember,
        rowHeight: 34,
        displaySafeCare: displaySafeCare,
        data: topDataToShow,
        onLabelAdd: props.onTopAdd,
        onLabelClick: props.onEmployeeClick ? props.onEmployeeClick : undefined,
        onClick: props.onTopRowClick ? props.onTopRowClick : undefined,
        onActivityClick: props.onTopActivityClick,
      },
      {
        id: 'planes',
        pane: 'planes',
        title: 'Aircraft',
        LabelElement: PlaneLabel,
        rowHeight: 34,
        RowElement: PlaneMember,
        data: bottomDataToShow,
        onLabelAdd: props.onBottomAdd,
        onLabelClick: undefined,
        onActivityClick: props.onBottomActivityClick,
        onClick: props.onBottomRowClick ? props.onBottomRowClick : undefined,
      },
    ],
    [topDataToShow, bottomDataToShow]
  )

  const common = {
    manual: props.manual,
    noheader: true,
    rosterData: props.rosterData,
    min: min,
    max: max,
    dragInProgress,
    pubStart: props.pubStart,
    pubEnd: props.pubEnd,
    dutyTimes: filter.displayDutyTimes,
    displayAirportTimes: filter.displayAirportTimes,
    period: period,
    onChangeScroll: setScrollX,
    onDragStart: setDragStart,
    onDragEnd: setDragEnd,
    xScale: scrollX.xScale,
    x: scrollX.x,
    width,
    scroll: scrollX,
    resetSavedScroll: resetSavedScroll,

    filteredCrew,
  }

  const zoomEvent = useCallback(
    (e) => {
      if (e.nativeEvent.shiftKey) {
        setScrollX((prevstate) => ({
          ...prevstate,
          x: Math.min(prevstate.x + e.nativeEvent.wheelDelta, prevstate.xScale.range()[1] - (width - 340)),
        }))
      }
      if (e.nativeEvent.ctrlKey) {
        const factor = e.nativeEvent.wheelDelta < 0 ? 1 / 1.5 : 1.5
        zoomDo(factor, left, width, e)
      }
    },
    [zoomDo, left, width]
  )

  useEffect(() => {
    if (!ref.current) return

    const onResize = () => {
      if (!ref.current) return
      const { left, width } = ref.current.getBoundingClientRect()
      setLeft(left)
      setWidth(width)
      setScrollX((prevstate) => ({
        ...prevstate,
        x: Math.min(prevstate.x, prevstate.xScale.range()[1] - (width - 340)),
        width: width - 340,
      }))
      //todo set width
      return { left, width }
    }
    const { left, width } = onResize()

    const myObserver = new ResizeObserver((entries, observer) => {
      onResize()
    })

    zoomDo(1, left, width)
    myObserver.disconnect()
    myObserver.observe(ref.current)
    return () => myObserver.disconnect()
  }, [ref.current])

  return (
    <div
      style={{ userSelect: 'none', height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      ref={ref}>
      <div style={{ height: 40, flex: '0 0 auto' }}>
        <RosterHeader pane={'header'} dragInProgress={dragInProgress} {...common} data={topDataToShow.length} />
      </div>
      <div
        ref={panesRef}
        onWheel={zoomEvent}
        style={{ display: 'flex', position: 'relative', flexDirection: 'column', flex: 1 }}>
        <DndContext sensors={sensors} autoScroll={false} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <SplitPane id={'roster_' + props.id}>
            <Pane hide={!props.heatmap || props?.heatmap.length === 0}>
              <RosterHeatmap {...splitPlaneConfig[0]} {...common} />
            </Pane>
            <Pane>
              <RosterGantt {...splitPlaneConfig[1]} {...common} />
            </Pane>
            <Pane>
              <RosterGantt {...splitPlaneConfig[2]} {...common} />
            </Pane>
          </SplitPane>
        </DndContext>
      </div>
    </div>
  )
}

export default React.memo(Split)
