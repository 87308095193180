import React, { useState, useEffect, Component } from 'react'
import { useSelector } from 'react-redux'
import { Popover2 } from '@blueprintjs/popover2'
import {
  Tabs,
  Tab,
  Navbar,
  Dialog,
  Icon,
  Card,
  Popover,
  RadioGroup,
  Radio,
  InputGroup,
  PopoverInteractionKind,
  Switch,
  ControlGroup,
  H5,
  Tag,
  Checkbox,
  Alignment,
  Button,
  Classes,
  Position,
} from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import { iconBedLarge } from 'icons'

import SearchDropdown from 'shared/SearchDropdown/SearchDropdown'
import SelectDropdown from 'shared/SearchDropdown/SelectDropdown'
import { CSSTransition, TransitionGroup, Transition } from 'react-transition-group'
import AirportDropdown from 'shared/AirportDropdown/AirportDropdown'
import { InputText } from 'shared/Forms'
import styles2 from '../Data.module.scss'
import styles from './ManualPairing.module.scss'
import { InputTime } from 'shared/Forms'
const use_for_tooltip_label = {
  required: 'Required',
  optional: 'Optional',
  no: 'Not applicable',
}
const use_for_tag_class = {
  required: styles.useForTagRequired,
  optional: styles.useForTagOptional,
  no: styles.useForTagNo,
}

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const daysOfWeekShort = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const StandbyActivity = (props) => {
  const [code, setCode] = useState(props.code)
  const [departureTime, setDepartureTime] = useState(props.departurTime)

  const [arrivalTime, setArrivalTime] = useState(props.arrivalTime)

  const [activityType, setActivityType] = useState(props.currentType)

  useEffect(() => {
    setActivityType(props.currentType)
  }, [props.currentType])

  return (
    <>
      <div>
        <Button
          icon="airplane"
          intent="primary"
          fill
          disabled={!code || !departureTime || !arrivalTime || arrivalTime === departureTime}
          style={{ marginBottom: 10 }}
          className={Classes.POPOVER_DISMISS}
          onClick={() =>
            props.onEnter({
              typeOfActivity: activityType,
              code: code,
              departureTime: departureTime,
              arrivalTime: arrivalTime,
            })
          }
          text={
            !props.onDelete ? 'Add Standby' : props.currentType === 'standby' ? 'Update Standby' : 'Change to Standby'
          }
        />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          placeContent: 'space-between',
          marginBottom: 10,
        }}>
        <InputTime nullAllowed value={departureTime} onChange={(value) => setDepartureTime(value)} />

        <Icon icon="arrow-right" style={{ marginLeft: 10, marginRight: 10 }} />
        <InputTime nullAllowed value={arrivalTime} onChange={(value) => setArrivalTime(value)} />
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          marginBottom: 10,
          width: '100%',
        }}>
        <InputText style={{ marginRight: 10 }} value={code} onChange={(e) => setCode(e)} placeholder="Standby Code" />
      </div>
    </>
  )
}

const FlightActivity = (props) => {
  const [flight, setFlight] = useState(props.flightNr)
  const [departure, setDeparture] = useState(props.departure)
  const [arrival, setArrival] = useState(props.arrival)
  const [occurrence, setoccurrence] = useState(props.occurrence?.dayOfWeek)

  const [activityType, setActivityType] = useState(props.currentType)

  useEffect(() => {
    setActivityType(props.currentType)
  }, [props.currentType])

  useEffect(() => {
    setoccurrence(props.occurrence?.dayOfWeek)
  }, [props.occurrence?.dayOfWeek])

  useEffect(() => {
    setFlight(props.flightNr)
  }, [props.flightNr])

  return (
    <>
      <div>
        <Button
          icon="airplane"
          intent="primary"
          fill
          disabled={!flight || !departure || !arrival || arrival === departure}
          style={{ marginBottom: 10 }}
          className={Classes.POPOVER_DISMISS}
          onClick={() =>
            props.onEnter({
              typeOfActivity: activityType,
              flightNumber: flight,
              departureAirport: departure,
              arrivalAirport: arrival,
              occurrence: { dayOfWeek: occurrence },
            })
          }
          text={
            !props.onDelete
              ? 'Add Flight'
              : props.currentType === 'flight' || props.currentType === 'iata' || props.currentType === 'deadhead'
              ? 'Update Flight'
              : 'Change to Flight'
          }
        />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          placeContent: 'space-between',
          marginBottom: 10,
        }}>
        <AirportDropdown activeItem={departure} onChange={(d) => setDeparture(d.iata)} />
        <Icon icon="arrow-right" style={{ marginLeft: 10, marginRight: 10 }} />
        <AirportDropdown activeItem={arrival} onChange={(d) => setArrival(d.iata)} />
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          marginBottom: 10,
          width: '100%',
        }}>
        <InputText style={{ marginRight: 10 }} value={flight} onChange={(e) => setFlight(e)} placeholder="Flight Nr." />
        <span style={{ marginLeft: 10, marginRight: 10 }}>on</span>
        <SelectDropdown
          disabled={activityType === 'iata'}
          hideFilter
          fill
          style={{ width: 150 }}
          width={150}
          activeItem={occurrence >= 1 ? daysOfWeek[occurrence - 1] : 'Any day'}
          items={['Any day', ...daysOfWeek]}
          onChange={(key) => setoccurrence(daysOfWeek.findIndex((d) => d === key) + 1)}
        />
      </div>
      <div>
        <RadioGroup
          inline={true}
          selectedValue={activityType}
          onChange={(e) => {
            if (e.target.value === 'iata') setoccurrence(0)
            setActivityType(e.target.value)
          }}>
          <Radio label="Flight" value="flight" />
          <Radio label="IATA" value="iata" />
          <Radio label="Deadhead" value="deadhead" />
        </RadioGroup>
      </div>
    </>
  )
}
const TaxiActivity = (props) => {
  const [departure, setDeparture] = useState(props.departure)
  const [departureTime, setDepartureTime] = useState(props.departureTime)
  const [arrival, setArrival] = useState(props.arrival)

  return (
    <>
      <Button
        fill
        icon="taxi"
        disabled={!departure || !arrival || arrival === departure}
        intent="primary"
        style={{ marginBottom: 10 }}
        className={Classes.POPOVER_DISMISS}
        onClick={() => {
          let o = {
            typeOfActivity: 'taxi',
            departureAirport: departure,
            arrivalAirport: arrival,
          }
          if (departureTime) o = { ...o, departureTime: departureTime }
          props.onEnter(o)
        }}
        text={!props.onDelete ? 'Add Taxi' : props.currentType === 'taxi' ? 'Update Taxi' : 'Change to Taxi'}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          placeContent: 'space-between',
          marginBottom: 10,
        }}>
        <InputTime
          placeholder="hh:mm"
          nullAllowed
          value={departureTime}
          onChange={(value) => setDepartureTime(value)}
        />
        <AirportDropdown activeItem={departure} onChange={(d) => setDeparture(d.iata)} /> <Icon icon="arrow-right" />
        <AirportDropdown activeItem={arrival} onChange={(d) => setArrival(d.iata)} />
      </div>
    </>
  )
}
const HotelActivity = (props) => {
  return (
    <Button
      fill
      icon={iconBedLarge}
      intent="primary"
      className={Classes.POPOVER_DISMISS}
      onClick={() =>
        props.onEnter({
          typeOfActivity: 'hotel',

          departureAirport: props.airport,
          arrivalAirport: props.airport,
        })
      }
      text={!props.onDelete ? 'Add Hotel' : props.currentType === 'hotel' ? 'Update Hotel' : 'Change to Hotel'}
    />
  )
}

const CreateNewActivity = (props) => {
  const [selectedTab, setTab] = useState(
    props.typeOfActivity === 'iata' || props.typeOfActivity === 'deadhead' ? 'flight' : props.typeOfActivity || 'flight'
  )

  /*
  useEffect(() => {
    if (props.typeOfActivity === 'iata' || props.typeOfActivity === 'deadhead') setTab('flight')
    else setTab(props.typeOfActivity)
  }, [props.typeOfActivity])
*/
  return (
    <div className={styles.newActivity}>
      <Navbar>
        <Navbar.Group>
          <Tabs fill large id="TabsExample" selectedTabId={selectedTab} onChange={(id) => setTab(id)}>
            <Tab id="flight" title="Flight" />
            <Tab id="taxi" title="Taxi" />
            {false && <Tab id="standby" title="Standby" />}
            <Tab id="hotel" title="Hotel" />
          </Tabs>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          {props.onDelete && (
            <Button className={Classes.POPOVER_DISMISS} minimal intent="danger" icon="trash" onClick={props.onDelete} />
          )}
          <Button className={Classes.POPOVER_DISMISS} minimal icon="cross" />
        </Navbar.Group>
      </Navbar>
      <div style={{ padding: 20 }}>
        {selectedTab === 'flight' && (
          <FlightActivity
            onDelete={props.onDelete}
            currentType={props.typeOfActivity}
            flightNr={props.flightNumber}
            departure={props.departureAirport}
            arrival={props.arrivalAirport}
            occurrence={props.occurrence}
            onEnter={(a) => {
              props.onSave(a)
            }}
          />
        )}
        {selectedTab === 'taxi' && (
          <TaxiActivity
            onDelete={props.onDelete}
            currentType={props.typeOfActivity}
            departure={props.departureAirport}
            departureTime={props.departureTime}
            arrival={props.arrivalAirport}
            onEnter={(a) => props.onSave(a)}
          />
        )}
        {selectedTab === 'hotel' && (
          <HotelActivity
            currentType={props.typeOfActivity}
            onDelete={props.onDelete}
            airport={props.departureAirport}
            onEnter={(a) => props.onSave(a)}
          />
        )}
        {selectedTab === 'standby' && (
          <StandbyActivity
            onDelete={props.onDelete}
            currentType={props.typeOfActivity}
            code={props.code}
            departureTime={props.departureTime}
            arrivalTime={props.arrivalTime}
            onEnter={(a) => {
              props.onSave(a)
            }}
          />
        )}
      </div>
    </div>
  )
}
const ActivityBlock = (props) => {
  const { data } = props

  const classes = [styles.activity, styles[data.typeOfActivity]]
  // if (this.state.hover) classes.push(styles.hover)
  let icon
  switch (data.typeOfActivity) {
    case 'flight':
    case 'iata':
    case 'deadhead':
      icon = 'airplane'
      break
    case 'standby':
      icon = 'cog'
      break
    case 'taxi':
      icon = 'taxi'
      break
    case 'hotel':
      icon = iconBedLarge

      break
  }

  return (
    <div>
      <ActivityDialog
        onSave={(a) => (props.onSave ? props.onSave(a) : undefined)}
        typeOfActivity={data.typeOfActivity}
        is_editable={props.is_editable}
        flightNumber={data.flightNumber}
        departureAirport={data.departureAirport}
        departureTime={data.departureTime}
        arrivalTime={data.arrivalTime}
        arrivalAirport={data.arrivalAirport}
        occurrence={data.occurrence}
        onDelete={props.onDelete}>
        <div
          className={
            data.typeOfActivity === 'hotel'
              ? [styles.hotel, styles.activityContainer].join(' ')
              : styles.activityContainer
          }>
          <div className={classes.join(' ')}>
            <div className={styles.activityWrapper}>
              <Icon icon={icon} />
            </div>
            {data.typeOfActivity !== 'hotel' && (
              <span className={styles.flightLabel}>
                {data.typeOfActivity === 'taxi'
                  ? `TAXI ${data.departureTime ? '(' + data.departureTime + ')' : ''}`
                  : `${data.flightNumber}${
                      data.occurrence?.dayOfWeek >= 1 ? ' (' + daysOfWeekShort[data.occurrence.dayOfWeek - 1] + ')' : ''
                    }`}
              </span>
            )}
          </div>
        </div>
      </ActivityDialog>
    </div>
  )
}

const DepLabel = (props) => {
  const equalPorts = props.previousPort === props.port
  return (
    <div className={styles.location}>
      <span className={styles.label}>
        {equalPorts && (
          <span className={props.typeOfActivity === 'hotel' ? styles.dutyEndLabel : undefined}>{props.port}</span>
        )}
        {!equalPorts && (
          <span style={{ color: equalPorts ? 'black' : 'red' }}>
            {props.previousPort} {props.port ? '|' : ''} {props.port}
          </span>
        )}
      </span>
      {props.is_editable && <Icon className={styles.plusButton} icon="plus" />}
    </div>
  )
}

const ActivityDialog = (props) => {
  const classes = [styles.plusButtonHoverArea]

  // if (props.visible || this.state.hover) classes.push(styles.hover)
  return (
    <Popover2
      portalClassName="theme-light"
      //className={styles.flex}
      enforceFocus={false}
      canEscapeKeyClose
      disabled={!props.is_editable}
      interactionKind={PopoverInteractionKind.CLICK}
      placement="bottom"
      usePortal={true}
      portalContainer={document.getElementById('main')}
      content={
        <div>
          <CreateNewActivity
            onDelete={props.onDelete}
            onSave={(a) => (props.onSave ? props.onSave(a) : undefined)}
            typeOfActivity={props.typeOfActivity}
            flightNumber={props.flightNumber}
            arrivalAirport={props.arrivalAirport}
            departureAirport={props.departureAirport}
            departureTime={props.departureTime}
            arrivalTime={props.departureTime}
            code={props.code}
            occurrence={props.occurrence}
          />
        </div>
      }>
      <span>{props.children}</span>
    </Popover2>
  )
}

const PlusButton = (props) => {
  return (
    <ActivityDialog
      typeOfActivity="flight"
      onSave={props.onAdd}
      is_editable={props.is_editable}
      key={props.key}
      departureAirport={props.departureAirport}
      arrivalAirport={props.arrivalAirport}
      arrivalTime={props.arrivalTime}
      departureTime={props.departureTime}
      code={props.code}
      flightNumber={props.flightNumber}>
      <DepLabel
        typeOfActivity={props.typeOfActivity}
        port={props.arrivalAirport}
        previousPort={props.departureAirport}
      />
    </ActivityDialog>
  )
}
const flatPairingToDataObject = (data, activities) => {
  const flightDuties = activities
    .filter((b) => {
      const a = JSON.parse(JSON.stringify(b))
      return a.typeOfActivity === 'hotel'
    })
    .map((d) => {
      return {
        activities: [],
      }
    })

  flightDuties.push({ activities: [] })
  let index = 0

  activities.forEach((b) => {
    const a = JSON.parse(JSON.stringify(b))

    if (a.typeOfActivity === 'hotel') index++
    else {
      flightDuties[index].activities.push(a)
    }

    if (!(a.occurrence?.dayOfWeek >= 1)) {
      delete a.occurrence
    }
  })

  return { ...data, flightDuties }
}

const flattenPairing = (homeBase, data_object) => {
  const arr = data_object
    .map((duty, i) => [...duty.activities, { description: 'key' + i, typeOfActivity: 'hotel' }])
    .flat()

  let prevPort = homeBase
  let prevId = null
  arr.forEach((d, i) => {
    if (!d.id && d.typeOfActivity !== 'hotel') {
      d.id = Math.round(Math.random() * 100000) + ''
    }
    if (d.typeOfActivity !== 'hotel') prevId = d.id
    if (d.typeOfActivity === 'hotel') d.id = prevId + 'hotel'

    if (d.typeOfActivity === 'hotel') {
      d.departureAirport = prevPort
      d.arrivalAirport = prevPort
    }

    prevPort = d.arrivalAirport
  })
  arr.pop() // remove last hotel

  return arr
}
const ManualPairing = (props) => {
  const { homeBase, offDaysBefore, offDaysAfter } = props.data

  const duties = flattenPairing(props.data.homeBase, props.data.flightDuties)

  let previousPort = homeBase
  return (
    <Card
      style={{ display: props.data.description.toUpperCase().includes(props.search.toUpperCase()) ? 'block' : 'none' }}
      className={styles2.card}
      interactive={props.is_editable}
      onClick={(e) => {
        e.stopPropagation()
        props.onClick(props.data, props.key)
      }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <H5 style={{ flex: 1 }}>{props.data.description}</H5>
        <span>
          <Tooltip2
            usePortal={false}
            intent="primary"
            content={use_for_tooltip_label[props.data.useForFCM ? props.data.useForFCM : 'required']}
            openOnTargetFocus={false}
            position={Position.BOTTOM}>
            <Tag
              className={use_for_tag_class[props.data.useForFCM ? props.data.useForFCM : 'required']}
              onClick={
                props.is_editable
                  ? (e) => {
                      let d = { ...props.data }
                      switch (props.data.useForFCM) {
                        case 'required':
                          d.useForFCM = 'optional'
                          break
                        case 'optional':
                          d.useForFCM = 'no'
                          break
                        case 'no':
                          d.useForFCM = 'required'
                          break
                      }
                      props.onChange(d)
                      e.stopPropagation()
                    }
                  : undefined
              }>
              FCM
            </Tag>
          </Tooltip2>
          <Tooltip2
            usePortal={false}
            intent="primary"
            content={use_for_tooltip_label[props.data.useForCCM ? props.data.useForCCM : 'required']}
            openOnTargetFocus={false}
            position={Position.BOTTOM}>
            <Tag
              className={use_for_tag_class[props.data.useForCCM ? props.data.useForCCM : 'required']}
              style={{ marginLeft: 4 }}
              onClick={
                props.is_editable
                  ? (e) => {
                      let d = { ...props.data }
                      switch (props.data.useForCCM) {
                        case 'required':
                          d.useForCCM = 'optional'
                          break
                        case 'optional':
                          d.useForCCM = 'no'
                          break
                        case 'no':
                          d.useForCCM = 'required'
                          break
                      }
                      props.onChange(d)
                      e.stopPropagation()
                    }
                  : undefined
              }>
              CCM
            </Tag>
          </Tooltip2>
        </span>
      </div>
      <div
        className={styles.pairing}
        onClick={(e) => {
          e.stopPropagation()
        }}>
        <span style={{ marginTop: 8, width: 70, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Icon className={styles.rest} icon="home" size={20} />
          <div style={{ fontSize: 10, height: 10, textTransform: 'uppercase' }}>
            {offDaysBefore > 0 && (
              <span>
                {offDaysBefore} off-day{offDaysBefore > 1 ? 's' : ''}
              </span>
            )}
          </div>
        </span>

        <PlusButton
          is_editable={props.is_editable}
          departureAirport={homeBase}
          arrivalAirport={duties[0] ? duties[0].departureAirport : undefined}
          flightNumber={duties[0] ? duties[0].flightNumber : undefined}
          onAdd={(a) => {
            const newActivities = JSON.parse(JSON.stringify(duties))
            newActivities.unshift(a)
            props.onChange(flatPairingToDataObject(props.data, newActivities))
          }}
        />
        <TransitionGroup className={styles.pairing}>
          {duties.map((a, i) => {
            const port = a.departureAirport

            previousPort = i > 0 ? duties[i - 1].arrivalAirport : homeBase

            return (
              <CSSTransition
                in
                timeout={300}
                key={props.data.description + i}
                exit
                classNames={{
                  enter: styles.myEnter,
                  enterActive: styles.myEnterActive,
                  exit: styles.myExit,
                  exitActive: styles.myExitActive,
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {i > 0 && (
                    <PlusButton
                      id={i}
                      is_editable={props.is_editable}
                      typeOfActivity={a.typeOfActivity}
                      arrivalAirport={i == 0 ? homeBase : a.departureAirport}
                      departureAirport={previousPort}
                      flightNumber={a.flightNumber}
                      onAdd={(a) => {
                        const newActivities = JSON.parse(JSON.stringify(duties))
                        newActivities.splice(i, 0, a)
                        props.onChange(flatPairingToDataObject(props.data, newActivities))
                      }}
                    />
                  )}

                  <ActivityBlock
                    data={a}
                    is_editable={props.is_editable}
                    onSave={(a) => {
                      const newActivities = JSON.parse(JSON.stringify(duties))
                      newActivities.splice(i, 1, a)
                      props.onChange(flatPairingToDataObject(props.data, newActivities))
                    }}
                    onDelete={() => {
                      const newActivities = JSON.parse(JSON.stringify(duties))
                      newActivities.splice(i, 1)
                      props.onChange(flatPairingToDataObject(props.data, newActivities))
                    }}
                  />
                </div>
              </CSSTransition>
            )
          })}
        </TransitionGroup>

        {duties.length > 0 && (
          <PlusButton
            is_editable={props.is_editable}
            arrivalAirport={duties.length > 0 ? homeBase : null}
            departureAirport={duties.length > 0 ? duties[duties.length - 1].arrivalAirport : homeBase}
            onAdd={(a) => {
              const newActivities = JSON.parse(JSON.stringify(duties))
              newActivities.push(a)
              props.onChange(flatPairingToDataObject(props.data, newActivities))
            }}
          />
        )}
        <span style={{ marginTop: 8, width: 70, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Icon className={styles.rest} icon="home" size={20} />
          <div style={{ fontSize: 10, height: 10, textTransform: 'uppercase' }}>
            {offDaysAfter > 0 && (
              <span>
                {offDaysAfter} off-day{offDaysAfter > 1 ? 's' : ''}
              </span>
            )}
          </div>
        </span>
      </div>
    </Card>
  )
}

const ManualPairings = (props) => {
  return (
    <>
      {props.data.items.map((item, i) => (
        <ManualPairing
          data={item}
          search={props.search}
          is_editable={props.is_editable}
          key={item.homeBase + item.description}
          onChange={(payload) => {
            const items = JSON.parse(JSON.stringify(props.data.items))
            items.splice(i, 1, payload)
            props.onChange({ items })
          }}
          onClick={(data, key) => {
            if (props.onClick) props.onClick(data, i)
          }}
        />
      ))}
    </>
  )
}

export default ManualPairings
