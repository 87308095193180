import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import api from '../api'

import { intl } from 'i18n/IntlGlobalProvider'
import DataObject from './DataObject.js'

import { H5, Tabs, Tab, Card, Switch, Slider, Alignment } from '@blueprintjs/core'
import Currency from './Configuration/Currency'
import StandBy from './Configuration/StandBy'
import store from 'store'
import { addContextDataObject } from './actions'
import SelectDropdown from 'shared/SearchDropdown/SelectDropdown'

const Comp = (props) => {
  const [tab, setTab] = useState('general')
  const { validation_errors } = props.data

  return (
    <DataObject
      tabbed={
        <Tabs selectedTabId={tab} onChange={(id) => setTab(id)} large>
          <Tab id="general" title="General" />

          <Tab id="rules" title="Rules" />
        </Tabs>
      }
      data={props.data}
      upload="json"
      title={intl.t('configuration')}
      onSave={props.onSave}>
      {tab === 'general' && (
        <>
          <Card style={{ margin: 20 }}>
            <Currency
              validation_errors={validation_errors?.body?.currency_info}
              is_editable={props.data.is_editable}
              data={props.data.payload.currency_info}
              onChange={(d) => {
                store.dispatch(
                  addContextDataObject({
                    ...props.data,
                    dirty: true,
                    payload: { ...props.data.payload, currency_info: d },
                  })
                )
              }}></Currency>
          </Card>
        </>
      )}
      {tab === 'standby' && <StandBy data={props.data.payload?.standby} />}
      {tab === 'rules' && (
        <>
          <Card style={{ margin: 20 }}>
            <H5>Soft Rules</H5>
            <div style={{ width: '100%' }}>
              <div>
                {Object.keys(props.data.payload?.soft_rules).map((d, i) =>
                  typeof props.data.payload.soft_rules[d] === 'boolean' ? (
                    <Switch
                      key={i}
                      alignIndicator={Alignment.RIGHT}
                      disabled={!props.data.is_editable}
                      checked={props.data.payload.soft_rules[d]}
                      onChange={() => {
                        let newSoftRules = { ...props.data.payload.soft_rules }
                        newSoftRules[d] = !newSoftRules[d]
                        store.dispatch(
                          addContextDataObject({
                            ...props.data,
                            dirty: true,
                            payload: { ...props.data.payload, soft_rules: newSoftRules },
                          })
                        )
                      }}
                      label={
                        <span
                          style={{
                            width: 300,

                            display: 'inline-block',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            verticalAlign: 'bottom',
                          }}>
                          {intl.t(d)}
                        </span>
                      }
                    />
                  ) : undefined
                )}
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ flex: 1, width: 300 }}> {intl.t('equal_flight_hour_distribution')} </span>
              <span style={{ flex: 0, width: 100, display: 'inline-block' }}>
                <SelectDropdown
                  width={100}
                  hideFilter
                  disabled={!props.data.is_editable}
                  activeItem={props.data.payload.soft_rules['equal_flight_hour_distribution']}
                  items={['off', 'low', 'medium', 'high']}
                  onChange={(e) => {
                    let newSoftRules = { ...props.data.payload.soft_rules }
                    newSoftRules['equal_flight_hour_distribution'] = e
                    store.dispatch(
                      addContextDataObject({
                        ...props.data,
                        dirty: true,
                        payload: { ...props.data.payload, soft_rules: newSoftRules },
                      })
                    )
                  }}
                />
              </span>
            </div>
          </Card>
        </>
      )}
    </DataObject>
  )
}

export default Comp
