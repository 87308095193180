import { Tag, Icon, Switch, Callout, Button, Collapse, Divider } from '@blueprintjs/core'
import { InputText } from 'shared/Forms'
import { Tooltip2 } from '@blueprintjs/popover2'
import React, { useState, useEffect } from 'react'
import CreateOrSaveDialog from 'shared/CreateOrSaveDialog'
import { PubEventType, PubState } from './actions'
import { useSelector } from 'react-redux'
import { InputDateRange } from 'shared/Forms'
import { DateTime, Interval } from 'luxon'
import SelectDropdown from 'shared/SearchDropdown/SelectDropdown'
import { InputDate } from 'shared/Forms'
import { DateInput2, DateRangeInput2 } from '@blueprintjs/datetime2'
import { LocaleUtils } from 'react-day-picker'
const myLocaleUtils = { ...LocaleUtils, getFirstDayOfWeek: () => 1 }

import { lux } from 'shared/LuxonHelpers'

const newPublication = {
  id: null,
  name: '',
  description: '',
  calculation_mode: 'regular',
  first_day: null,
  last_day: null,
  data_src: 'idps',
  data_dst: 'idps',
  no_auto_trigger_event_types: [],
}

const changeAutoTriggerEvents = (value, data, setData) => {
  let newValue = [...data.no_auto_trigger_event_types]
  let findIndex = newValue.findIndex((d) => d === PubEventType.CALC_FLIGHT_COV_START)
  if (findIndex >= 0) newValue.splice(findIndex, 1)
  else newValue.push(PubEventType.CALC_FLIGHT_COV_START)
  setData({ ...data, no_auto_trigger_event_types: newValue })
}

const CreateNewPublicationDialog = (props) => {
  const activePublication = useSelector((state) => state.rosters.activePublication)
  // const [valid, setValid] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { state } = activePublication
  const { data } = props
  const idpsDatesDisabled =
    state === PubState.IDPS_IMPORT_READY ||
    state === PubState.IDPS_IMPORT_WAITING ||
    state === PubState.END ||
    state === PubState.ABORTED
  const autoStartDisabled =
    props.data === null ? false : state !== PubState.CONFIG_DATA_READY && state !== PubState.IDPS_EXPORT_WAITING
  const datesDisabled = props.data === null ? false : state !== PubState.CONFIG_DATA_READY
  let n_days = 0
  let is_week_based = false
  let is_month_based = false

  return (
    <CreateOrSaveDialog
      usePortal={false}
      icon="info-sign"
      resetObject={newPublication}
      edit={props.data}
      validate={(data) => {
        const datesValid =
          (data?.data_dst_roster_from ? lux(data?.data_dst_roster_from) : -Infinity) <
          (data?.data_dst_roster_until ? lux(data?.data_dst_roster_until) : Infinity)
        const valid = data?.first_day && data?.last_day && datesValid
        return valid
      }}
      onDelete={props.onDelete}
      onCreate={props.onCreate}
      onSave={props.onSave}
      onClose={() => {
        props.onClose()
      }}
      title="Publication"
      isOpen={props.isOpen}>
      {(data, setData, error) => {
        const datesPresent = data?.first_day && data?.last_day
        if (datesPresent) {
          n_days = Interval.fromDateTimes(lux(data?.first_day), lux(data?.last_day)).length('days') + 1 // Includes start & end days
          is_week_based = lux(data?.first_day).weekday === 1 && lux(data?.last_day).weekday === 7
          is_month_based = lux(data?.first_day).day === 1 && lux(data?.last_day).day === lux(data?.last_day).daysInMonth
        }
        return (
          <div>
            <div style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
              <InputText
                fill
                placeholder="Name"
                value={data.name}
                error={error?.name}
                errorposition="top"
                onChange={(name) => setData({ ...data, name })}
              />
            </div>
            <div style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
              <InputDateRange
                disabled={datesDisabled}
                error={[{ fromDate: error?.fromDate, toDate: error?.toDate }]}
                from={data?.first_day}
                to={data?.last_day}
                onChange={(dates) => {
                  setData({
                    ...data,
                    first_day: dates[0],
                    last_day: dates[1],
                  })
                }}
              />
              <Tooltip2
                usePortal={true}
                portalContainer={document.getElementById('main')}
                position={'bottom-right'}
                content={
                  <Callout
                    intent={!datesPresent ? 'primary' : is_week_based || is_month_based ? 'success' : 'danger'}
                    title={!datesPresent ? 'Info' : is_week_based || is_month_based ? 'All OK' : 'Warning'}
                    style={{ marginBottom: 20, width: 450 }}>
                    <div>
                      There are specific rules in place for defining publication periods. Either of the following should
                      hold:
                      <ul>
                        <li>
                          Week-based publications should start on a Monday and end on a Sunday; possibly spanning
                          multiple weeks. The total publication length should therefore be a multiple of 7 days.
                        </li>
                        <li>
                          Month-based publications should start of the first day of a month and end on the last day of a
                          month; possibly spanning multiple months. The precise length depends on the exact months.
                        </li>
                      </ul>
                      {datesPresent && (
                        <p>
                          Currently selected period <Tag>{data?.first_day}</Tag> &mdash; <Tag>{data?.last_day}</Tag>{' '}
                          starts on a {lux(data?.first_day).weekdayLong}, ends on a {lux(data?.last_day).weekdayLong}{' '}
                          and is {n_days} days long.
                          <br />
                          {is_month_based || is_week_based
                            ? 'This appears to be a valid publication period.'
                            : 'This publication period does not conform to the above rules. Data export can be triggered but unforeseen side-effects might occur during roster calculation.'}
                        </p>
                      )}
                    </div>
                  </Callout>
                }>
                <Icon
                  style={{ marginLeft: 10 }}
                  iconSize={20}
                  intent={!datesPresent ? 'primary' : is_week_based || is_month_based ? 'success' : 'danger'}
                  icon={!datesPresent ? 'info-sign' : is_week_based || is_month_based ? 'tick-circle' : 'error'}
                />
              </Tooltip2>
            </div>
            <InputText
              area="true"
              fill
              placeholder="Description"
              style={{ height: 100, marginBottom: 10 }}
              value={data.description}
              onChange={(description) => setData({ ...data, description })}
            />
            <SelectDropdown
              hideFilter
              disabled={datesDisabled}
              fill
              items={['regular', 'flexible']}
              activeItem={data?.calculation_mode || 'regular'}
              text={(e) => (e === 'regular' ? 'Regular Calculation' : 'Flex Calculation')}
              onChange={(e) =>
                setData({
                  ...data,
                  calculation_mode: e,
                  data_dst: data?.data_src === 'manual_input' ? '' : e === 'regular' ? 'idps' : '',
                })
              }
            />
            <Divider style={{ marginBottom: 10, marginTop: 10 }} />
            <Switch
              label="Auto-start coverage calculation after successful data export"
              disabled={autoStartDisabled}
              checked={!data?.no_auto_trigger_event_types?.includes(PubEventType.CALC_FLIGHT_COV_START)}
              onChange={(value) => changeAutoTriggerEvents(value, data, setData)}
            />
            <Divider style={{ marginBottom: 10, marginTop: 10 }} />
            <Button
              minimal
              icon={isOpen ? 'caret-down' : 'caret-right'}
              text="Advanced settings"
              onClick={() => setIsOpen(!isOpen)}
            />
            <Collapse isOpen={isOpen}>
              <Switch
                label="Manual Data Management - Creates a sandbox publication with manually managed input data, detached from external data sources"
                checked={data?.data_src === 'manual_input'}
                disabled={props.data}
                onChange={(el) => {
                  setData({
                    ...data,
                    data_src: data?.data_src === 'manual_input' ? 'idps' : 'manual_input',
                    data_dst: data?.data_src === 'idps' ? '' : data.data_dst,
                  })
                }}
              />
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              <div style={{ marginLeft: 37 }}>
                <div style={{ marginBottom: 5 }}>Restrict activities published to IDPS</div>
                <InputDate
                  timezone={'UTC'}
                  disabled={idpsDatesDisabled}
                  placeholder="Start Date"
                  value={data?.data_dst_roster_from}
                  onChange={(value) => setData({ ...data, data_dst_roster_from: value ? lux(value).toISO() : value })}
                />
                <span style={{ marginLeft: 10, marginRight: 10 }}>to</span>
                <InputDate
                  timezone={'UTC'}
                  disabled={idpsDatesDisabled}
                  placeholder="End Date"
                  value={data?.data_dst_roster_until}
                  onChange={(value) =>
                    setData({ ...data, data_dst_roster_until: value ? lux(value).endOf('day').toISO() : value })
                  }
                />
              </div>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Collapse>
          </div>
        )
      }}
    </CreateOrSaveDialog>
  )
}

export default CreateNewPublicationDialog
