import { produce } from 'immer'
import {
  STORE_ACTIVE_PUBLICATION,
  STORE_ALL_PUBLICATIONS,
  STORE_NEW_PUBLICATION,
  CHANGE_FILTER,
  CHANGE_LOADING,
  CREATING_PUBLICATION,
  UPDATE_HEAVY_DATA_OBJECT,
  ADD_CONTEXT_DATA_OBJECT,
  CHANGE_PUBLICATION_SEARCH,
  TOGGLE_EXCLUDED_CREWMEMBER_FROM_UPLOAD,
  SET_SELECTED_CREWMEMBERS,
  RESET_FILTER,
  STORE_COVERAGE_ROSTER,
  STORE_ROSTER,
  CLEAR_PUBLICATION,
  SET_EXPANDED_CREWMEMBERS,
} from './actions'

import api from './api'

const initialFilter = {
  displaySafeCare: false,
  displayDutyTimes: true,
  displayAirportTimes: false,
  displayIssues: false,
  filterScore: 1,
  filterBlockhours: false,
  filterNightsAway: false,
  filterIgnoredPreAssignedPairingIssues: false,
  filterHeatmap: 'Both',
  toggleExcludedCrewmembers: false,
  toggleSplitPreAssigned: false,
  searchRosterList: null,
  sortBy: 'last_modified_at',
  sortDir: 'desc',
  searchText: null,
  searchPlCode: [],
  searchSpecialCode: [],
  searchPlane: [],
  searchHomeBase: [],
  searchSecondBase: [],
  searchFunction: [],
  searchQualifications: [],
  searchAirportQualifications: [],
  searchFlightNumber: [],
  searchActivityCode: [],
  searchFlight: '',
  filterIssueType: [],
  filterIssueDataOrObject: 'roster',
  filterSeverityWarning: false,
  filterSeverityError: false,
  filterSeverityBlocking: false,
  includeSecondBase: false,
  showArchived: false,
  showManual: false,
  showFlex: false,
  filterTypeOfActivity: [],
  filterCrewOnFlightId: '',
  selectedDay: null,
  timezone: 'UTC',
}
const initialState = {
  contexts: [],
  activePublication: {},
  filterPublication: {
    searchPublication: '',
  },
  sortBy: 'last_modified_at',
  sortDir: 'desc',
  excludedCrewMembers: [],
  selectedCrewMembers: [],
  expandedCrewMembers: [],
  filter: initialFilter,
  loading: false,
  creatingPublication: false,
  error: null,
  publications: undefined,
}

export default function rosterReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PUBLICATION_SEARCH:
      return produce(state, (draftState) => {
        draftState.filterPublication.searchPublication = action.payload
      })
    case ADD_CONTEXT_DATA_OBJECT:
      return produce(state, (draftState) => {
        let data_object_index = draftState.activePublication.data_objects.findIndex((d) => d.id === action.payload.id)
        if (data_object_index >= 0)
          draftState.activePublication.data_objects.splice(data_object_index, 1, action.payload)
        else draftState.activePublication.data_objects.push(action.payload)
      })
    case STORE_COVERAGE_ROSTER:
      return produce(state, (draftState) => {
        draftState.activePublication.rosterCoverageCheckData = action.payload
      })
    case CLEAR_PUBLICATION:
      return produce(state, (draftState) => {
        draftState.activePublication = {}
      })
    case STORE_ROSTER:
      return produce(state, (draftState) => {
        draftState.activePublication.rosterData = action.payload
      })
    case UPDATE_HEAVY_DATA_OBJECT: {
      let { pub_id, object_id, json } = action.payload

      return produce(state, (draftState) => {
        api.enrichRoster(json.payload.roster)
        draftState.activePublication.data_objects.find((d) => d.id === object_id).payload = json.payload
      })
    }
    case CHANGE_FILTER: {
      const newfilter = { ...state.filter }

      return {
        ...state,
        filter: { ...newfilter, ...action.payload },
      }
    }
    case RESET_FILTER:
      return {
        ...state,
        filter: initialFilter,
      }
    case CHANGE_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case CREATING_PUBLICATION:
      return {
        ...state,
        creatingPublication: action.payload,
      }
    case STORE_ACTIVE_PUBLICATION:
      return {
        ...state,
        activePublication: { ...state.activePublication, ...action.payload },
      }

    case STORE_ALL_PUBLICATIONS:
      return {
        ...state,
        publications: action.payload,
      }
    case STORE_NEW_PUBLICATION:
      return {
        ...state,
        publications: [...state.publications, action.payload],
      }
    case TOGGLE_EXCLUDED_CREWMEMBER_FROM_UPLOAD:
      return {
        ...state,
        filter: {
          ...state.filter,
          toggleExcludedCrewmembers: action.payload.length === 0 ? false : state.filter.toggleExcludedCrewmembers,
        },
        excludedCrewMembers: action.payload,
      }
    case SET_SELECTED_CREWMEMBERS:
      return produce(state, (draftState) => {
        if (action.payload === null) {
          draftState.selectedCrewMembers = []
        } else {
          let index = draftState.selectedCrewMembers.findIndex((d) => d === action.payload)

          if (index >= 0) draftState.selectedCrewMembers.splice(index, 1)
          else draftState.selectedCrewMembers.push(action.payload)
        }
      })
    case SET_EXPANDED_CREWMEMBERS:
      return produce(state, (draftState) => {
        if (action.payload === null) {
          draftState.expandedCrewMembers = []
        } else {
          let index = draftState.expandedCrewMembers.findIndex((d) => d === action.payload)

          if (index >= 0) draftState.expandedCrewMembers.splice(index, 1)
          else draftState.expandedCrewMembers.push(action.payload)
        }
      })
    default:
      // ALWAYS have a default case in a reducer
      return state
  }
}
