import React, { useState, useEffect } from 'react'
import { Dialog, Classes, Button, Callout } from '@blueprintjs/core'
import store from 'store'
import { storeDataObjectvalidation_errors } from './actions'

const AsyncFunction = (async () => {}).constructor

const CreateOrSaveDialog = (props) => {
  const { isInvalid } = props
  const [payload, setPayload] = useState(props.payload)
  const [data, setData] = useState()
  const [error, setError] = useState(null)
  const [editIndex, setEditIndex] = useState()
  let editmode = props.isOpen >= 0
  let originalData

  useEffect(() => {
    if (props.isOpen >= 0) {
      originalData = props.payload?.items?.[props.isOpen]
      setData(originalData)
      setPayload(JSON.parse(JSON.stringify(props.payload || {})))
      setEditIndex(props.isOpen)
    }
    if (props.isOpen === 'new') {
      let newPayload = JSON.parse(JSON.stringify(props.payload))
      newPayload.items.push(props.resetObject)
      setPayload(newPayload)
      setData(props.resetObject)
      setEditIndex(newPayload.items.length - 1)
    }
  }, [props.isOpen])

  const closeAction = () => {
    setError(null)
    const action = storeDataObjectvalidation_errors({ id: props.id, errors: null })
    store.dispatch(action)
    setPayload(null)
    props.onClose()
  }

  const deleteAction = () => {
    let newPayload = JSON.parse(JSON.stringify(payload))
    newPayload.items.splice(editIndex, 1)
    setPayload(newPayload)
    props.onSave(props.id, newPayload)
    props.onClose()
  }

  const saveAction = () => {
    let newPayload = JSON.parse(JSON.stringify(payload))
    newPayload.items.splice(editIndex, 1, data)
    setPayload(newPayload)
    const action = storeDataObjectvalidation_errors({ id: props.id, errors: null })
    store.dispatch(action)
    setError(null)
    if (props.onSave instanceof AsyncFunction === true) {
      props
        .onSave(props.id, newPayload)
        .then((err) => {
          if (err) props.onClose()
        })
        .catch((err) => {
          setError(err)
        })
    } else {
      props.onSave(props.id, newPayload)
      props.onClose()
    }
  }

  return (
    <Dialog
      {...props}
      isOpen={props.isOpen !== null}
      usePortal={true}
      portalContainer={document.getElementById('main')}
      canEscapeKeyClose={false}
      onClose={closeAction}
      title={props.edit ? 'Edit ' + props.title : 'Create new ' + props.title}>
      <div className={Classes.DIALOG_BODY}>
        {props.children(data, setData, error?.items?.[editIndex])}
        {error && <Callout intent="danger">Validation Error. Please review the highlighted fields.</Callout>}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {editmode && (
            <Button
              intent="danger"
              style={{ marginRight: 'auto', marginLeft: 0 }}
              icon="trash"
              onClick={deleteAction}
              text="Delete"
            />
          )}
          <Button onClick={closeAction} text="Cancel" />
          <Button
            icon="tick"
            disabled={isInvalid}
            intent="primary"
            onClick={saveAction}
            text={props.edit ? 'Save' : 'Create'}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default CreateOrSaveDialog
