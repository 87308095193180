import { Button, NonIdealState, Position, Spinner } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import HeaderRow from 'shared/HeaderRow'
import orderBy from 'lodash/orderBy'
import memoizeOne from 'memoize-one'
import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { FixedSizeList as VirtualList } from 'react-window'

import { Row, Rows, Cols, Col } from 'shared/PanelLayout/PanelLayout'
import store from 'store/index'
import config from 'config'
import { storeActivePublication } from './actions'
import api from './api'
import { SizeMe } from 'react-sizeme'
import PublicationsFilter from './PublicationsFilter'
import CreateNewPublicationDialog from './NewPublicationDialog'
import PublicationListItem from './PublicationListItem'
import dashboardApi from 'dashboard/api'
import { lux } from 'shared/LuxonHelpers'
import logReducer from 'log/reducer'

/*\  {props.list.map((item) => (
        <PublicationListItem key={item.id} item={item} />
      ))}
      */

const List = (props) => {
  const scroll = useRef()
  const ListItem = ({ index, style }) => (
    <div style={style}>
      <PublicationListItem item={props.list[index]} />
    </div>
  )
  return (
    <SizeMe monitorHeight style={{ flex: 1 }}>
      {({ size }) => {
        return (
          <VirtualList
            initialScrollOffset={scroll.current}
            height={size.height}
            onScroll={(e) => {
              scroll.current = e.scrollOffset
            }}
            itemData={props.list}
            itemCount={props.list?.length}
            itemSize={80}>
            {ListItem}
          </VirtualList>
        )
      }}
    </SizeMe>
  )
}

const beginning = lux(new Date()).minus({ months: 12 })
const initialFilter = {
  search: '',
  showArchived: false,
  startDate: null,
  endDate: null,
  sortBy: 'last_modified_at',
  sortDir: 'desc',
}
const CRosters = (props) => {
  const [filter, setFilter] = useState(initialFilter)
  const [isOpen, setIsOpen] = useState(null)
  const [filterVisible, setFilterVisible] = useState(false)
  const [editeableItem, setEditableItem] = useState(null)
  const { sortBy, sortDir } = filter
  const activeContext = useSelector((state) => state.user.profile.active_context)
  const publications = useSelector((state) => state.rosters.publications)
  const creatingPublication = useSelector((state) => state.rosters.creatingPublication)
  const loading = useSelector((state) => state.rosters.loading)

  let polling

  useEffect(() => {
    store.dispatch(storeActivePublication({}))
  }, []) //clear active roster

  useEffect(() => {
    const startPolling = () => {
      stopPolling()
      polling = setInterval(() => {
        api.getAllPublications(activeContext)
        dashboardApi.storeAllPublications(activeContext)
      }, config.pollingInterval)
    }
    const stopPolling = () => {
      if (polling) {
        clearInterval(polling)
      }
    }
    api.getAllPublications(activeContext)
    dashboardApi.storeAllPublications(activeContext)
    startPolling()
    return () => stopPolling()
  }, [activeContext])

  const handleSorting = (a, b) => {
    if (a[sortBy] > b[sortBy]) return sortDir == 'asc' ? 1 : -1
    if (a[sortBy] < b[sortBy]) return sortDir == 'asc' ? -1 : 1
    return 0
  }
  let sortedList = React.useMemo(
    () =>
      publications
        ?.filter((d) => d.context === activeContext)
        .filter((d) => (filter.showArchived ? true : d.archived_at === null))
        .filter((d) => (filter.showFlex ? d.calculation_mode === 'flexible' : true))
        .filter((d) => (filter.showManual ? d.data_src === 'manual_input' : true))
        .filter((d) => {
          return filter.search ? d.name.toUpperCase().includes(filter.search.toUpperCase()) : true
        })
        .filter((d) => {
          if (!filter.startDate && !filter.endDate) return true
          if (!filter.endDate) return lux(d.last_day) >= lux(filter.startDate)
          if (!filter.startDate) return lux(d.first_day) <= lux(filter.endDate)

          return !(lux(d.last_day) <= lux(filter.startDate) || lux(d.first_day) >= lux(filter.endDate))
        })
        .sort(handleSorting),
    [publications, sortBy, sortDir, activeContext, filter]
  )

  return (
    <Cols>
      <Col>
        <CreateNewPublicationDialog
          isOpen={isOpen}
          data={null}
          onClose={() => setIsOpen(null)}
          onDelete={(data) => api.archivePublication(data.id)}
          onSave={(data) =>
            api.updatePublication(
              data.id,
              {
                name: data.name,
                description: data.description,
                calculation_mode: data.calculation_mode,
                data_src: data.data_src,
                data_dst: data.data_dst,
                data_dst_roster_from: data.data_dst_roster_from,
                data_dst_roster_until: data.data_dst_roster_until,
                first_day: data.first_day,
                last_day: data.last_day,
                no_auto_trigger_event_types: data.no_auto_trigger_event_types,
              },
              false,
              activeContext
            )
          }
          onCreate={async (data) =>
            await api.createNewPublication(
              activeContext,
              data.name,
              data.description,
              data.calculation_mode,
              data.data_src,
              data.data_dst,
              data.data_dst_roster_from,
              data.data_dst_roster_until,
              data.first_day,
              data.last_day,
              data.no_auto_trigger_event_types
            )
          }
        />
        <Rows>
          <HeaderRow icon="list" title={`Publications of ${activeContext} Airline`}>
            <Button
              fill
              onClick={() => {
                setEditableItem(null)
                setIsOpen(true)
              }}
              icon="add"
              text="Create new"
              intent="primary"
            />
            {!filterVisible && <Button onClick={() => setFilterVisible(true)} icon="filter" minimal />}
          </HeaderRow>
          <Row muted>
            {!loading && sortedList?.length === 0 && (
              <div style={{ flex: 1, textAlign: 'center', fontSize: 20, marginTop: 50 }}>
                No publications. Check your filter criteria...
              </div>
            )}
            {publications === undefined && loading && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Spinner />
              </div>
            )}
            <List list={sortedList} />
          </Row>
        </Rows>
      </Col>
      <Col collapse="right" collapsed={!filterVisible} fixed width={280} dark>
        <PublicationsFilter filter={filter} onClose={() => setFilterVisible(false)} onFilterChanged={setFilter} />
      </Col>
    </Cols>
  )
}

export default CRosters
