import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import api from './api'
import DataObject from './DataObject'
import { intl } from 'i18n/IntlGlobalProvider'
import ManualPairings from './ManualPairing/ManualPairing'
import { Divider, RadioGroup, Radio } from '@blueprintjs/core'
import Search from 'shared/search'
import Validate from './Validate.js'
import CreateOrSaveDialog from './DataObjectItemDialog'
import AirportDropdown from './../shared/AirportDropdown/AirportDropdown'
import SelectDropdown from 'shared/SearchDropdown/SelectDropdown'
import store from 'store'
import { addContextDataObject } from './actions'
import { InputText } from 'shared/Forms'
import ErrorBoundary from 'log/ErrorBoundary'
const newPairing = {
  description: '',
  homeBase: '',
  flightDuties: [{ activities: [] }],
  useForCCM: 'required',
  useForFCM: 'required',
}

const CreateNewPairingDialog = (props) => {
  const availableCrewBases = props.configuration?.crew_bases || []
  const error =
    props.isOpen === 'new'
      ? props.validation_errors?.[props.dataObject.payload.items.length]
      : props.validation_errors?.[props.isOpen]

  return (
    <CreateOrSaveDialog
      usePortal={false}
      payload={props.dataObject.payload}
      id={props.dataObject.id}
      icon="info-sign"
      title="Pairing"
      edit={props.isOpen >= 0 && props.isOpen !== 'new'}
      resetObject={newPairing}
      onClose={props.onClose}
      onSave={props.onSave}
      isOpen={props.isOpen}>
      {(data, setData) => (
        <>
          <div>
            <InputText
              fill
              placeholder="Description"
              value={data?.description}
              onChange={(description) => setData({ ...data, description })}
              style={{ marginBottom: 10, clear: 'both' }}
            />
          </div>
          <div>
            <AirportDropdown
              error={error?.homeBase}
              placeholder=" Home Base"
              items={availableCrewBases}
              fill
              activeItem={data?.homeBase}
              onChange={({ iata }) => setData({ ...data, homeBase: iata })}
              style={{ marginBottom: 10 }}
            />
          </div>
          <Divider />
          <div style={{ display: 'flex' }}>
            <span style={{ flex: 1 }}>
              <RadioGroup
                label="Use for FCM scheduling:"
                onChange={(e) => setData({ ...data, useForFCM: e.target.value })}
                selectedValue={data?.useForFCM || 'required'}>
                <Radio label="required" value="required" />
                <Radio label="optional" value="optional" />
                <Radio disabled={data?.useForCCM === 'no'} label="not applicable" value="no" />
              </RadioGroup>
            </span>
            <span style={{ flex: 1 }}>
              <RadioGroup
                label="Use for CCM scheduling:"
                onChange={(e) => setData({ ...data, useForCCM: e.target.value })}
                selectedValue={data?.useForCCM || 'required'}>
                <Radio label="required" value="required" />
                <Radio label="optional" value="optional" />
                <Radio disabled={data?.useForFCM === 'no'} label="not applicable" value="no" />
              </RadioGroup>
            </span>
          </div>
          <Divider />
          <div style={{ display: 'flex', marginBottom: 10 }}>
            <span style={{ flex: 1 }}>
              <span>Off-days before:</span>
              <span style={{ display: 'inline-block', width: 50, marginLeft: 10 }}>
                <SelectDropdown
                  activeItem={data?.offDaysBefore >= 0 ? data?.offDaysBefore + '' : '0'}
                  hideFilter
                  items={['0', '1', '2', '3']}
                  onChange={(value) => setData({ ...data, offDaysBefore: parseInt(value) })}
                />
              </span>
            </span>
            <span style={{ flex: 1 }}>
              <span>Off-days after:</span>
              <span style={{ display: 'inline-block', width: 50, marginLeft: 10 }}>
                <SelectDropdown
                  activeItem={data?.offDaysAfter >= 0 ? data?.offDaysAfter + '' : '0'}
                  hideFilter
                  items={['0', '1', '2', '3']}
                  onChange={(value) => setData({ ...data, offDaysAfter: parseInt(value) })}
                />
              </span>
            </span>
          </div>
        </>
      )}
    </CreateOrSaveDialog>
  )
}

const Comp = (props) => {
  const [search, setSearch] = useState('')
  const [dialog, setDialog] = useState(null)

  const { data } = props
  const { payload, is_editable } = data

  return (
    <>
      <CreateNewPairingDialog
        configuration={props.configuration}
        validation_errors={data.validation_errors?.items}
        isOpen={dialog}
        dataObject={props.data}
        onSave={props.onSave}
        onClose={() => {
          setDialog(null)
        }}
      />
      <DataObject
        data={props.data}
        upload="json"
        title={intl.t('manual_pairings')}
        search={<Search placeholder="Search" value={search} onChange={(value) => setSearch(value)} />}
        onAdd={() => setDialog('new')}
        is_editable={is_editable}
        onSave={props.onSave}
        onChange={(data) => store.dispatch(addContextDataObject(data))}
        onUndo={() => api.getDataObject(data.id)}
        nofilter>
        <ManualPairings
          data={props.data.payload}
          search={search}
          is_editable={is_editable}
          onChange={(payload) => {
            const o = { ...props.data, payload: payload, dirty: true }
            store.dispatch(addContextDataObject(o))
          }}
          onClick={
            is_editable
              ? (data, key) => {
                  setDialog(key)
                }
              : undefined
          }
        />
      </DataObject>
    </>
  )
}

export default Comp
