import store from 'store'

import React, { useEffect, useLayoutEffect, useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import styles from './RosterGantt.module.scss'
import activityStyles from './CrewMember/Activity/Activity.module.scss'
import { Checkbox, Tag, Button, Icon, Menu, MenuItem, MenuDivider } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import Header from './Header/Header'
import { endOf, startOf, getTicksInInterval, format } from 'shared/LuxonHelpers'

import TimezoneDropdown from 'shared/TimezoneDropdown/TimezoneDropdown'

import { resetFilter, changeFilter, setSelectedCrewMembers } from '../actions'
import Cursor from './Cursor/Cursor'
import { lux } from 'shared/LuxonHelpers'
import TooltipButton from 'shared/TooltipButton'

const Scroller = (props) => {
  const ref = useRef()
  const { xScale, width } = props
  const [left, setLeft] = useState(0)
  const [dragStart, setDragStart] = useState(null)

  const [scrolls, setScroll] = useState(0)
  const [scrollState, setScrollState] = useState({ x: 0, y: 0 })
  const filter = useSelector((state) => state.rosters.filter)
  const { selectedDay, timezone } = filter

  useLayoutEffect(() => {
    const myObserver = new ResizeObserver((entries, observer) => {
      if (!ref.current) return
      setLeft(ref.current.getBoundingClientRect().left)
    })
    myObserver.observe(ref.current)
  }, [ref])

  const selectedCrew = useSelector((state) => state.rosters.selectedCrewMembers)

  useEffect(() => {
    if (props.draggedItem) setDragStart(null)
  }, [props.draggedItem])

  useEffect(() => {
    if (!props.dragInProgress) setDragStart(null)
  }, [props.dragInProgress])

  useEffect(() => {
    if (props.resetSavedScroll) ref.current.scrollTop = scrollState.y
  }, [props.resetSavedScroll])

  const crewMembers = React.useMemo(() => props.rosterData.filter((d) => d.crewFunction !== 'Plane'))

  const changeTimezone = useCallback(
    (tz) =>
      store.dispatch(
        changeFilter({
          timezone: tz.key,
        })
      ),
    [store]
  )

  const setSelected = useCallback(() => {
    store.dispatch(setSelectedCrewMembers(null))
  }, [store])

  const applyFilter = useCallback(() => {
    console.log(selectedCrew)
    store.dispatch(
      changeFilter({
        searchPlCode: selectedCrew,
      })
    )
    store.dispatch(setSelectedCrewMembers(null))
  }, [store, selectedCrew])

  const selectedTag = React.useMemo(
    () => (
      <>
        <Tag
          onClick={setSelected}
          minimal
          style={{ width: 100, textAlign: 'center', lineHeight: 2.1, paddingRight: 0 }}
          round>
          {selectedCrew?.length} selected{' '}
          <Button small minimal icon={<Icon size={12} icon="cross" />} style={{ borderRadius: '50%' }} />
        </Tag>
        <TooltipButton tooltip={'Set as filter'} icon="filter-keep" minimal onClick={applyFilter} />
      </>
    ),
    [selectedCrew]
  )

  const tag = React.useMemo(
    () => (
      <Tag minimal round style={{ width: 100, textAlign: 'center', lineHeight: 2.1, marginRight: 30 }}>
        {props.data} crew{' '}
        {props.data !== crewMembers.length && (
          <Button
            small
            minimal
            icon={<Icon size={12} icon="cross" onClick={() => store.dispatch(resetFilter())} />}
            style={{ borderRadius: '50%' }}
          />
        )}
      </Tag>
    ),
    [props.data]
  )

  return (
    <div style={{ display: 'flex' }}>
      <div ref={ref} style={{ position: 'absolute', right: 0, top: 0, bottom: 0, left: 340 }}>
        {!props.dragInProgress && <Cursor left={left} xScale={xScale} x={props.x} timezone={timezone} />}
        {selectedDay && (
          <>
            {xScale(startOf(selectedDay, timezone, 'day')) - props.x > 0 && (
              <div
                className={styles.selectedDay}
                style={{
                  left: 0,
                  width: Math.min(width, xScale(startOf(selectedDay, timezone, 'day')) - props.x),
                }}
              />
            )}
            {xScale(endOf(selectedDay, timezone, 'day')) - props.x < width && (
              <div
                className={styles.selectedDay}
                style={{
                  left: Math.max(0, xScale(endOf(selectedDay, timezone, 'day')) - props.x),
                  right: 0,
                }}
              />
            )}
          </>
        )}
      </div>
      <div className={styles.settings}>
        {false && (
          <Popover2
            usePortal={true}
            PopupKind="Menu"
            portalContainer={document.getElementById('main')}
            placement={'bottom-start'}
            content={
              <Menu>
                <MenuItem
                  shouldDismissPopover={false}
                  icon={<Checkbox style={{ marginTop: 10 }} />}
                  onClick={() => {}}
                  text="Missing Crew Heatmap"
                />
                <MenuItem
                  shouldDismissPopover={false}
                  icon={<Checkbox style={{ marginTop: 10 }} />}
                  onClick={() => {}}
                  text="Scheduled Crew"
                />
                <MenuItem
                  shouldDismissPopover={false}
                  icon={<Checkbox style={{ marginTop: 10 }} />}
                  onClick={() => {}}
                  text="Scheduled Planes"
                />
                <MenuDivider />
                <MenuItem text="Settings..." icon="cog" intent="primary">
                  <MenuItem icon="tick" text="Save on edit" />
                  <MenuItem icon="blank" text="Compile on edit" />
                </MenuItem>
              </Menu>
            }
            renderTarget={({ isOpen, ref, ...targetProps }) => (
              <Button {...targetProps} active={isOpen} elementRef={ref} icon="cog" minimal style={{ marginRight: 5 }} />
            )}
          />
        )}

        <span style={{ marginRight: 34 }}></span>
        <TimezoneDropdown width={150} activeItem={timezone} onChange={changeTimezone} />
        {selectedCrew?.length > 0 && <span style={{ marginLeft: 10 }}>{selectedTag}</span>}
        {!selectedCrew?.length && <span style={{ marginLeft: 10 }}>{tag}</span>}
      </div>
      <div className={styles.rosterRight} style={{ position: 'relative' }}>
        <div
          style={{
            top: 0,
            height: 40,
            overflow: 'hidden',
          }}>
          <Header xScale={xScale} x={props.x} period={props.period} width={width} />
        </div>
      </div>
    </div>
  )
}

export default React.memo(Scroller)
