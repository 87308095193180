import React from 'react'
import { render } from 'react-dom'
//import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Provider, useSelector } from 'react-redux'
import { Navigate, Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import { View } from 'shared//PanelLayout/PanelLayout'
import { HotkeysProvider } from '@blueprintjs/core'
import Login from './auth/login'
import IntlGlobalProvider from 'i18n/IntlGlobalProvider'
import translationsForUsersLocale from 'i18n/translation.json'
import './index.scss'
import ErrorBoundary from 'log/ErrorBoundary'
import store from 'store'
import styles from 'MainLeftNavBar.module.scss'
import authApi from 'auth/api'
import config from 'config'
import Test from 'shared/SplitPane/SplitPane'
Sentry.init({
  dsn: config.sentry.dsn,
  release: config.sentry.release,
  environment: config.sentry.environment,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

// Disable all log statements in production builds
//if (process.env !== 'development') console.log = function () {}

let locale = 'en'

/*let locale = navigator.language

// Fall back to language if locale does not exist. eg -> pt-BR => pt
if (!translationsForUsersLocale.hasOwnProperty(locale)) {
  locale = locale.split('-')[0];
}
*/
const Main = (props) => {
  //  const theme = useSelector((state) => state.user.theme)
  const theme = useSelector((state) => state?.user?.profile?.preferences?.ui?.mode) || 'theme-light'

  return (
    <ErrorBoundary page>
      <View id="main" className={theme}>
        <Router>
          <Routes>
            <Route path="/test" element={<Test />} />
            <Route path="/*" element={<Login />} />
          </Routes>
        </Router>
      </View>
    </ErrorBoundary>
  )
}

const startApp = async () => {
  return render(
    <Provider store={store}>
      <IntlProvider locale={locale} messages={translationsForUsersLocale[locale]}>
        <IntlGlobalProvider>
          <Main />
        </IntlGlobalProvider>
      </IntlProvider>
    </Provider>,

    document.getElementById('root')
  )
}
startApp()

/* for React 18
const startApp = async () => {
  const container = document.getElementById('root')
  const root = createRoot(container)

  return root.render(
    <Provider store={store}>
      <IntlProvider locale={locale} messages={translationsForUsersLocale[locale]}>
        <IntlGlobalProvider>
          <Main />
        </IntlGlobalProvider>
      </IntlProvider>
    </Provider>
  )
}
startApp()
*/
