// process.env contains the config parameters set in the active .env file

const config = {
  version: process.env.REACT_APP_VERSION,

  baseUrl: process.env.REACT_APP_BASE_URL,
  api: process.env.REACT_APP_API,
  pollingInterval: 30 * 1000,
  landingPage: '/publications',
  nonProductionBanner: process.env.REACT_APP_NON_PRODUCTION_BANNER,
  ui: {
    allowDatesInThePastForCourseRequests: process.env.REACT_APP_UI_ALLOW_DATES_IN_THE_PAST === 'true' ? true : false,
  },
  OAuth: {
    clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
    redirectUrl: process.env.REACT_APP_OAUTH_REDIRECT_URL,
    authorizeEndpoint: process.env.REACT_APP_OAUTH_AUTHORIZE_ENDPOINT,
    tokenEndpoint: process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT,
    logoutEndpoint: process.env.REACT_APP_OAUTH_LOGOUT_ENDPOINT,
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_VERSION,
    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  },
  supportEmail: process.env.REACT_APP_SUPPORT_EMAIL,
  features: {
    isHelpPageEnabled: process.env.REACT_APP_FEATURES_IS_HELP_PAGE_ENABLED === 'true' ? true : false,
  },
}

export default {
  // Add common config values here
  ...config,
}
