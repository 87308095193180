import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Tag, Button, Icon } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import styles from './PaneHeader.module.scss'
const PaneHeader = (props) => {
  const [collapsed, setCollapsed] = useState(props.collapsed)

  useEffect(() => {
    setCollapsed(props.collapsed)
  }, [props.collapsed])

  return (
    <div className={collapsed ? styles.collapsed : styles.expanded}>
      {!collapsed && (
        <Popover2
          usePortal={true}
          PopupKind="Menu"
          portalContainer={document.getElementById('main')}
          placement={'bottom-start'}
          content={props.menu}
          renderTarget={({ isOpen, ref, ...targetProps }) => (
            <Button
              {...targetProps}
              active={isOpen}
              elementRef={ref}
              icon="more"
              minimal
              style={{ marginTop: 5, marginBottom: 5 }}
            />
          )}
        />
      )}

      <span
        className={styles.title}
        onDoubleClick={() => {
          setCollapsed(!collapsed)
          props.onCollapseChange(!collapsed)
        }}>
        {props.title}
      </span>
      {props.onLabelAdd && <Button fill icon="plus" onClick={() => props.onLabelAdd('new')} />}
      <span style={{ flex: 1 }} />
      <Button
        minimal
        disabled={props.disableCollapse}
        className={styles.collapseButton}
        icon={collapsed ? 'double-chevron-down' : 'double-chevron-up'}
        onClick={() => {
          setCollapsed(!collapsed)
          props.onCollapseChange(!collapsed)
        }}
      />
    </div>
  )
}

export default React.memo(PaneHeader)
