import { Alignment, Button, Menu, MenuItem } from '@blueprintjs/core'
import { Select2 } from '@blueprintjs/select'
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import styles from './SearchDropdown.module.scss'
import { FixedSizeList as List } from 'react-window'

const SelectDropdown = (props) => {
  const targetRef = useRef()
  const [selected, setSelected] = useState(props.activeItem)
  useEffect(() => setSelected(props.activeItem), [props.activeItem])
  const { disabled, placeholder, fill, style, hideFilter, width } = props

  function itemListRenderer(itemProps) {
    return (
      <Menu style={{ padding: 0, width: props.width, minWidth: props.width }} popoverProps={{ captureDismiss: true }}>
        {itemProps.filteredItems.length === 0 && props.noResults}
        <List
          height={Math.min(150, itemProps.filteredItems.length * 35)}
          itemCount={itemProps.items.length}
          itemSize={35}
          width={props.width}>
          {({ index, style }) => <div style={style}>{itemProps.renderItem(itemProps.items[index], index)}</div>}
        </List>
      </Menu>
    )
  }

  return (
    <Select2
      itemListRenderer={itemListRenderer}
      disabled={disabled}
      popoverProps={{
        captureDismiss: true,
        usePortal: props.usePortal !== undefined ? props.usePortal : true,
        minimal: true,
        matchTargetWidth: true,

        portalContainer: document.getElementById('main'),
        fill: props.width ? false : true,
        position: 'bottom',
        modifiers: {
          computeStyle: {
            gpuAcceleration: false,
          },
        },
        popoverClassName: styles.popover,
      }}
      popoverTargetProps={{
        style: { style: { ...style, width: props.width || 200 } },
        className: fill ? styles.fill : undefined,
      }}
      filterable={hideFilter ? false : true}
      itemPredicate={(query, item, _index, exactMatch) => item.text.toLowerCase().indexOf(query.toLowerCase()) >= 0}
      noResults={<MenuItem disabled={true} text="No results." />}
      resetOnSelect={true}
      resetOnClose
      inputProps={{
        style: { width: props.width },
        placeholder: placeholder,
      }}
      onItemSelect={(item) => {
        setSelected(item.key)
        props.onChange(item.key)
      }}
      activeItem={
        selected !== null
          ? { key: selected, value: selected, text: props.text ? props.text(selected) : selected }
          : null
      }
      items={props.items.map((key) => {
        return { key: key, value: key, text: props.text ? props.text(key) : key }
      })}
      itemRenderer={(item, { handleClick, modifiers, query }) => {
        return (
          <MenuItem
            key={item.key}
            text={item.text}
            disabled={modifiers.disabled}
            active={selected === item.key}
            onClick={handleClick}
            label={item.label}
          />
        )
      }}>
      <Button
        ref={targetRef}
        style={{ width: props.width }}
        alignText={Alignment.LEFT}
        disabled={disabled}
        fill
        rightIcon="caret-down"
        text={selected !== null ? (props.text ? props.text(selected) : selected) : props.placeholder}
      />
    </Select2>
  )
}

export default SelectDropdown
